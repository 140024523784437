<template>
	<div class="pui-form">
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<berth-form-header :modelPk="modelPk"></berth-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#maintab'">{{ $t('berth.tabs.maintab') }}</v-tab>
				<v-tab v-if="!isCreatingElement && $store.getters.hasFunctionality('LIST_BERTHBLOCK')" :key="1" :href="'#block'">{{
					$t('berth.tabs.block')
				}}</v-tab>
				<v-tab :key="2" :href="'#locationsequence'" v-if="$store.getters.hasFunctionality('READ_LOCATIONSEQUENCEHEADER')">{{
					$t('berth.tabs.locationsequence')
				}}</v-tab>
				<v-tab :key="3" :href="'#bollardnoray'" v-if="$store.getters.hasFunctionality('READ_BOLLARD')">{{
					$t('berth.tabs.bollardnoray')
				}}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'maintab'">
					<v-flex xs12>
						<berth-form-main-tab
							:modelName="modelName"
							:parentObject="this.model"
							:formDisabled="formDisabled"
							:isCreatingElement="isCreatingElement"
						></berth-form-main-tab>
					</v-flex>
				</v-tab-item>
				<v-tab-item :key="1" lazy :value="'block'" v-if="!isCreatingElement && $store.getters.hasFunctionality('LIST_BERTHBLOCK')">
					<v-flex xs12>
						<pui-master-detail
							componentName="berthblockgrid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ id: 'locationid' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
							:parentModel="model"
						></pui-master-detail>
					</v-flex>
				</v-tab-item>
				<v-tab-item :key="2" lazy :value="'locationsequence'" v-if="$store.getters.hasFunctionality('READ_LOCATIONSEQUENCEHEADER')">
					<v-flex xs12>
						<pui-master-detail
							componentName="locationsequenceheader-grid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ id: 'locationfrom' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
							:parentModel="model"
						></pui-master-detail>
					</v-flex>
				</v-tab-item>
				<v-tab-item :key="3" lazy :value="'bollardnoray'" v-if="$store.getters.hasFunctionality('READ_BOLLARD')">
					<v-flex xs12>
						<pui-master-detail
							componentName="bollardnoray-grid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ id: 'berthid' }"
						></pui-master-detail>
					</v-flex>
				</v-tab-item>
			</v-tabs-items>
			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import BerthFormHeader from './BerthFormHeader.vue';
import BerthFormMainTab from './BerthFormMainTab.vue';
import BerthblockGrid from '../berthblock/BerthblockGrid.vue';
import LocationsequenceheaderGrid from '../locationsequenceheader/LocationsequenceheaderGrid.vue';

export default {
	name: 'berth-form',
	mixins: [PuiFormMethodsMixin],
	components: { BerthFormHeader, BerthFormMainTab, BerthblockGrid, LocationsequenceheaderGrid },
	data() {
		return {
			modelName: 'berth'
		};
	},
	mounted() {
	},
	methods: {
		afterGetData() {}
	},
	computed: {},
	watch: {}
};
</script>
